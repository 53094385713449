<template>
    <Modal class="modal-pdf size-xxl" v-if="opened" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ title }}
        </template>
        <template v-slot:body>
            <object class="pdf-view" :data="uri" type="application/pdf" >
                <embed :src="uri" type="application/pdf" />
            </object>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';
import config from 'config';
import { httpService } from "@common/services/http.service";
import { useStore } from 'vuex';

export default {
    name: "ModalPdf",
    components: {
        Modal
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        pdf_url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.document');
            }
        }
    },
    setup(props,context){        
        const closeForm = () => {
            context.emit('close');
        };


        const axiosConfig = {
            baseURL: config.apiUrl,
        }
        const httpClient = httpService(axiosConfig);
        const uri = ref('');

        const store = useStore(); 

        watchEffect(() => {
            uri.value = '';
            if(!props.pdf_url){
                return;
            }
            httpClient.get(props.pdf_url,{
                responseType: 'blob',
            })
                .then(response => {
                    uri.value = (window.URL || window.webkitURL).createObjectURL(response.data)
                },
                error => {
                    store.dispatch('alert/error', error, { root: true });
                });
        })
        

        
        return {
            closeForm,
            uri
        }
    }
}
</script>

<style scoped>
.pdf-view{
    width: 100%;
    height: 75vh;
}

</style>